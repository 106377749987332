import VERSION from './VERSION';


let version = '';

fetch(VERSION)
 .then(r => r.text())
 .then(text => {
    version = text;
});

export const config = {
    "contract_addr": {
        "mainnet": "neutron156u7mg0dqq8ssm5x0332ucep3zkg3d6dnxalyaw6429vsw3kyq3q8snpyk",
    },
    "active_lcd": "mainnet",
    "lcd": {
        "testnet": {
            "pion-1": {
                "chainID": "pion-1",
                "lcd": "https://neutron-testnet-api.polkachu.com:443",
                "gasAdjustment": 1.75,
                "gasPrices": {
                    "uluna": 0.015
                },
                "prefix": "neutron"
            }
        },
        "mainnet": {
            "neutron-1": {
                "chainID": "neutron-1",
                "lcd": "https://neutron-api.polkachu.com:443",
                "gasAdjustment": 1.75,
                "gasPrices": {
                    "uluna": 0.015
                },
                "prefix": "neutron"
            }
        }
    },   
    "enabled_tokens": [
        {name: "atom", display:"ATOM", icon:"assets/images/atom.svg", type:"ibc", chain_id: "cosmoshub-4", native_denom: "uatom", address:"ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9"}, 
        //{name: "luna", display:"LUNA", icon:"assets/images/luna.svg", type:"ibc", chain_id: "phoenix-1", native_denom: "uluna", address:"ibc/322C86EB54A505E28AFE380CED1721FA61E9580A7548A16B9DCF6E7C8CEE43D5"}, 
        //{name: "astro", display:"ASTRO", icon:"assets/images/astro.svg", type:"native", chain_id: "neutron-1", native_denom: "factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro", address:"factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro"}, 
        {name: "ntrn", display:"NTRN", icon:"assets/images/ntrn.svg", type:"native", chain_id: "neutron-1", native_denom: "untrn", address:"untrn"}
    ]
}

export function getChainId(active_lcd){
    return Object.keys(config.lcd[active_lcd])[0];
}

export function getToken(active_lcd, denom){
//    return config[denom][active_lcd];
    return config.enabled_tokens.find((t) => t.name == denom).address
}

export function getContractAddress(active_lcd){
    return config.contract_addr[active_lcd];
}

export function getEnabledTokens(){
    return JSON.parse(JSON.stringify(config.enabled_tokens));
}


export function parseWallet(w){
    let wallet = {};

    if (!w) return wallet;
    wallet.address = w.account.address;
    wallet.chainId = w.network.chainId;
    wallet.network = Object.keys(config.lcd).find((n) => config.lcd[n][w.network.chainId] ); 
    wallet.connectedWallet = w;
    wallet.type = w.providerId;

    return wallet;
  }

export function getVersion(){
    return version;
}