import React from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import { getToken, config } from '../config';


function Success(props) {

    const [data, setData] = React.useState({  });

    function close(){
        props.close();
    }

    function formatAmount(a){
        if (isNaN(a)) return a;
        let value = parseFloat(a);
        if (value == parseInt(a)) return value.toFixed(0);
        else return value.toFixed(4); 
    }

    return (
        <div className='successBody' style={props.receiveAmountAnew > 0 && props.receiveAmountAcAnew > 0 ? null : {height: 275+'px'}}>
            <table>
                <tr>
                    <td colSpan={3} className='logoTd'>
                            <img className='fireIcon' src='assets/images/fire.gif'></img>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='successText successTextDown'>Burned</div>
                    </td>
                    <td rowSpan={2}>
                        <img className='rightIcon' src="assets/images/down.svg"></img>
                    </td>
                    <td>
                        <div className='successText successTextDown'>Received</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='successText successTextUp'>{formatAmount(props.burnedAmount)} {props.burnedDenom.toUpperCase()}</div>
                    </td>
           
                    <td>
                        <div className='successText successTextUp'>
                            {props.receiveAmountAnew > 0 ? <p>{formatAmount(props.receiveAmountAnew)} ANEW </p> : null}
                            {props.receiveAmountAcAnew > 0 ? <p>{formatAmount(props.receiveAmountAcAnew)} acANEW </p> : null}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>                
                        <div className='successText successTextCenter'>
                        <a className="modalLink" href={'https://neutron.celat.one/neutron-1/txs/'+props.tx}  target='_blank'>{props.tx.substring(0,5) + "..." + props.tx.substring(props.tx.length-5, props.tx.length)}</a> </div>
                    </td>
                </tr>
   
            </table>
            <img className="closeIcon" onClick={close} src='assets/images/close.png'></img>
        </div>
    )
}

export default Success