import React from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import { getToken, config, parseWallet } from '../config';


function Burnlog(props) {


    //const [data, setData] = React.useState(null);
    const [tokensBurned, setTokensBurned] = React.useState(null);

    const active_lcd = props.connectedWallet ? parseWallet(props.connectedWallet).network : config.active_lcd;
    var burnlog_data;

    React.useEffect(() => {
        if (!tokensBurned) axios.get('/api/' + active_lcd + '/burnlog').then((res) => {
                let rows = [];
                for (let i=0;i< config.enabled_tokens.length;i++){
                    rows.push(getTokenRow(res.data.burn_log, config.enabled_tokens[i]));
                }
                setTokensBurned(rows);            
        });
    });

    function handleChange(e) {
        let t = e.target;
        while (t.attributes.name == null) {
            t = t.parentElement;
        }
        props.setCurrentPage("burn");

    }


    function getTokenRow(data, t) {
        console.log(data);
        console.log(t);
        let tokenAmount = data.items[t.address] ? data.items[t.address].token_amount : null;

        return <tr>
            <td className='burnLogIconCol'><img className="coinIcon" src={t.icon}></img></td>
            <td className='burnLogCol'><div className='burnLogItem'>{t.display}</div></td>
            <td className='burnLogCol'><div className='burnLogItemRight'>{isNaN(tokenAmount) ? '...' : (tokenAmount / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 })}</div></td>
        </tr>
    }

    return (
        <div >
            {
                // isNaN(data.native_amount) ?
                // <img className='fireIcon' style={{ 'margin': '56px'}} src='assets/images/fire.gif'></img>
                // :
                // <table className='burnlog'>
                //     <tr>
                //         <td className='burnLogIconCol'><img className="coinIcon" src='assets/images/luna.svg'></img></td>
                //         <td className='burnLogCol'><div className='burnLogItem'>LUNA</div></td>
                //         <td className='burnLogCol'><div className='burnLogItemRight'>{isNaN(data.native_amount) ? '...' : (data.native_amount / 1000000).toLocaleString(undefined, { maximumFractionDigits: 0 })}</div></td>
                //         {/* <td className='burnLogCol'><div className='burnLogItem'>on {data.native_count} transactions</div></td> */}
                //     </tr>
                //     {config.enabled_tokens.map(getTokenRow)}
                // </table>
            }
            <div className='burnLogCol'>&nbsp;</div>
            <div>{tokensBurned}</div>
        </div>
    )
}

export default Burnlog