import React, { useCallback, useState, useEffect } from 'react';
import { Buffer } from 'buffer'
import { getChainId, getContractAddress, parseWallet } from '../config';
import axios from 'axios';
import {
  MsgExecuteContract,
} from "@delphi-labs/shuttle";

function TxToken(props) {
  const amountToken = props.amountToken;
  const tokenAddress = props.tokenAddress;
  const returnAmount = props.returnAmount;
  const getWallets = props.getWallets;
  const recentWallet = props.recentWallet;
  const broadcast = props.broadcast;
  const workflowParams = props.workflowParams;

  const [txResult, setTxResult] = useState(null);
  const [txLink, setTxLink] = useState(false);
  const [success, setSuccess] = useState(null);
  const [tryAgain, setTryAgain] = useState(null);

  const connectedWallet = recentWallet;//getWallets({ chainId: "neutron-1" })[0];



  let chainID;
  if (connectedWallet) chainID = getChainId(parseWallet(connectedWallet).network);

  function updateTx(network, txResult) {
    console.log(`/api/${network}/tx?txhash=${txResult.hash}`);
    axios.get(`/api/${network}/tx?txhash=${txResult.hash}`).then((r) => {
      console.log(r);
      if (r.data.code == -1) {
        setTimeout(function () { updateTx(network, txResult) }, 1000);
      } else if (r.data.code == 0) {
        // setTxMessage(["Burn DAO"]);
        // setSuccess(true);
        // setTxLink(true);
        props.setShowSuccess(true);
        props.setTxhash(txResult.hash);
        window.startAnimation();
      } else {
        props.setErrorMessage("Oops! We have a problem. Developers have been notified.");
        // setTxMessage(["Oops!","No Burn For You", "Developers have been informed of this egregious error!"]);
        // setTryAgain(true);
        // setTxLink(true);
      }
    });
  }


  const postTx = useCallback(async () => {
    if (!connectedWallet) {
      return;
    }

    // if (parseWallet(connectedWallet).network === 'mainnet') {
    //   alert(`Please only execute this example on Testnet`);
    //   return;
    // }

    try {
      // const transactionMsg = {
      //   chainID,
      //   msgs: [
      //     new MsgExecuteContract(
      //       parseWallet(connectedWallet).address,
      //       tokenAddress,
      //       {
      //         send: {
      //           amount: '' + (amountToken),
      //           contract: getContractAddress(parseWallet(connectedWallet).network),
      //           msg: Buffer.from(`{ "burn_token": { "native_amount": "${returnAmount}" }}`).toString('base64')
      //         },
      //       },
      //     )
      //   ],
      //   //fee: new Fee(1000000, [new Coin('untrn', 10000)]) // we need to up gas_limit as the wallet's default is not enough (350000)
      // };

      let msg_workflow_params = [];
      let total_burn_amount = 0;
      let burn_denom;
      for(const w of workflowParams){
        msg_workflow_params.push({
          id: w.workflow_id,
          burn_amount: ""+w.burn_amount,
          base_amount: ""+w.base_amount,
          denom: w.denom
        })
        total_burn_amount += w.burn_amount;
        burn_denom = w.denom;
      }

      let transactionMsg = [
        new MsgExecuteContract({
          sender: parseWallet(connectedWallet).address,
          contract: getContractAddress(parseWallet(connectedWallet).network),
          msg:  {
            "burn": {
              workflow_params:msg_workflow_params
            }
          },
          funds: [{ amount: ""+total_burn_amount, denom: burn_denom }, { amount: "200000", denom: "untrn"}]
        }),
        //TODO: cw20 requires a send
        // new MsgExecuteContract(
        //   parseWallet(connectedWallet).address,
        //   tokenAddress,
        //   {
        //     send: {
        //       amount: '' + (amountToken),
        //       contract: getContractAddress(parseWallet(connectedWallet).network),
        //       msg: Buffer.from(`{ "burn_token": { "native_amount": "${returnAmount}" }}`).toString('base64')
        //     },
        //   },

      ];


      console.log(transactionMsg);

      broadcast({
        wallet: recentWallet,
        messages: transactionMsg,
        mobile: false, //TODO: use import { isMobile } from "./utils/device";
        feeAmount: "10000",
        gasLimit: "2000000"
      })
        .then((tx) => {
          console.log("Broadcast result", tx);
          setTxResult(tx);
          props.setModalMessage(["fire"]);
          updateTx(parseWallet(connectedWallet).network, tx);
        })
        .catch((error) => {
          console.log(error);
        });

    } catch (error) {
      console.log(error);
    }
  }, [connectedWallet]);


  useEffect(() => {
    postTx()
  }, []);


  function handleTryAgain() {
    window.location.reload();
  }

  return (
    <>
      {/* {success ? <><p className='modalSubText'>You burned {props.amountToken/1000000} ROAR and received {props.returnAmount/1000000} ANEW</p> <p className='modalSubText'>Stake your ANEW at <a className='modalSubLink' href='https://dao.enterprise.money/dao?address=terra1ss492tutlydk6ap3ha8cs8ewcscrsnpd72nfud4s4muuy98ypmfqmz8eys'>cosmoburn</a> to receive a share of every burn!</p> </>: null}
      {txResult && txResult.txhash && txLink ? <a className="modalLink" href={'https://finder.terra.money/'+connectedWallet.network+'/tx/'+txResult.txhash}  target='_blank'>Find your Tx</a> : null}      
      {tryAgain ? <div className="modalButtons"><input type="submit" value="Try Again" onClick={handleTryAgain} /></div> : null}       */}
    </>
  );
}
export default TxToken;
