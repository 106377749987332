import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import {
  CosmostationExtensionProvider,
  KeplrExtensionProvider,
  LeapCosmosExtensionProvider,
  MetamaskExtensionProvider,
  StationExtensionProvider,
  XDEFICosmosExtensionProvider,
  CosmostationMobileProvider,
  KeplrMobileProvider,
  LeapCosmosMobileProvider,
  LeapMetamaskCosmosSnapExtensionProvider,
  MetamaskMobileProvider,
  ShuttleProvider,
  OkxWalletExtensionProvider,
  NinjiExtensionProvider,
  OWalletCosmosExtensionProvider,
  CosmiframeExtensionProvider,
  CitadelOneExtensionProvider,
} from "@delphi-labs/shuttle-react";

import { NEUTRON_MAINNET, NEUTRON_TESTNET } from './networks.js';

const extensionProviders = [
  new KeplrExtensionProvider({
    networks: [NEUTRON_MAINNET, NEUTRON_TESTNET],
  }),
  new LeapCosmosExtensionProvider({
    networks: [NEUTRON_MAINNET, NEUTRON_TESTNET],
  }),
  // ...
];

const mobileProviders = [
  new KeplrMobileProvider({
    networks: [NEUTRON_MAINNET, NEUTRON_TESTNET],
  }),
  new LeapCosmosMobileProvider({
    networks: [NEUTRON_MAINNET, NEUTRON_TESTNET],
  }),
];

const WC_PROJECT_ID = "CosmoBurn";


    ReactDOM.render(
      <ShuttleProvider
          walletConnectProjectId={WC_PROJECT_ID}
          mobileProviders={mobileProviders}
          extensionProviders={extensionProviders}
          persistent
        >
        <App />
      </ShuttleProvider>,
      document.getElementById('root'),
    );
  