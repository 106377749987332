import React from 'react'
import './style.scss'
import Main from './Components/Main'
import { CookiesProvider, useCookies } from 'react-cookie'

function App(){

    const [cookies, setCookie] = useCookies(['user'])

    function handleAgreement(new_map) {
        let map = {...new_map};
        setCookie('isAgreement', map, { path: '/' })
    }

    return(
        <CookiesProvider>
            <Main isAgreement={cookies.isAgreement || {'1':false, '2':false}} handleAgreement={handleAgreement}/>
        </CookiesProvider>
    )
}

export default App